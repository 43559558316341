import { useStaticQuery, graphql } from "gatsby";
import { LangType, useLang } from "./i18n";

interface Category {
  id: number;
  name: string;
  sort: number;
}

export class Categories {
  constructor(
    private readonly lang: LangType,
    private readonly sessionCategories: {
      name: { en: string; ja: string };
      id: number;
      sort: number;
    }[]
  ) {}

  get list(): Category[] {
    return this.sessionCategories
      .sort((c1, c2) => c1.sort - c2.sort)
      .map((s) => ({ id: s.id, name: s.name[this.lang], sort: s.sort }));
  }

  of(id: number): Category {
    const c = this.sessionCategories.filter((c) => c.id === id)[0];
    const title = c.name[this.lang];
    return { name: title, id, sort: c.sort };
  }
}

interface Speaker {
  name: string;
  profilePicture: string | undefined;
  tagLine: string;
  bio: string;
  links: { title: string; url: string }[];
}

export class Speakers {
  constructor(
    private readonly data: {
      id: string;
      fullName: string;
      profilePicture: string | undefined;
      tagLine: string;
      bio: string;
    }[]
  ) {}
  of(id: string): Speaker {
    const s = this.data.filter((s) => s.id === id)[0];
    return {
      name: s.fullName,
      profilePicture: s.profilePicture,
      tagLine: s.tagLine,
      bio: s.bio,
      links: [],
    };
  }
}

export interface Session {
  id: string;
  title: string;
  speakerIds: string[];
  description: string;
  categoryId: number;
  startsAt: string;
  endsAt: string;
  room: number;
  language: LangType | undefined;
  interpretationTarget: boolean;
  message: string | undefined;
}

const createSessions = (
  lang: LangType,
  data: {
    id: string;
    description: string;
    sessionCategoryItemId: number;
    speakers: string[];
    title: { ja: string; en: string };
    startsAt: string;
    endsAt: string;
    roomId: number;
    language: string;
    interpretationTarget: boolean;
    message: string | null;
  }[]
): Session[] => {
  return data.map((s) => ({
    id: s.id,
    title: s.title[lang],
    description: s.description,
    speakerIds: s.speakers,
    categoryId: s.sessionCategoryItemId,
    startsAt: s.startsAt,
    endsAt: s.endsAt,
    room: s.roomId,
    message: s.message ? s.message : undefined,
    language:
      s.language === "ENGLISH"
        ? "en"
        : s.language === "JAPANESE"
        ? "ja"
        : undefined,
    interpretationTarget: s.interpretationTarget,
  }));
};

export interface Room {
  name: string;
  id: number;
  sort: number;
}

const createRooms = (
  lang: LangType,
  data: {
    name: { en: string; ja: string };
    id: number;
    sort: number;
  }[]
): Room[] =>
  data.map((r) => ({
    id: r.id,
    name: r.name[lang],
    sort: r.sort,
  }));

export interface AllData {
  sessions: Session[];
  speakers: Speakers;
  categories: Categories;
  rooms: Room[];
}

export const useTimetable = (): AllData => {
  const lang = useLang();
  const {
    allTimetable: { data },
  } = useStaticQuery(graphql`
    {
      allTimetable {
        data {
          categories {
            id
            items {
              name {
                en
                ja
              }
              id
              sort
            }
          }
          sessions {
            description
            sessionCategoryItemId
            speakers
            title {
              en
              ja
            }
            id
            endsAt
            startsAt
            roomId
            language
            interpretationTarget
          }
          speakers {
            id
            fullName
            profilePicture
            tagLine
            bio
          }
          rooms {
            name {
              en
              ja
            }
            id
            sort
          }
        }
      }
    }
  `);
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const { categories, speakers, sessions, rooms } = data;

  return {
    categories: new Categories(
      lang,
      categories.filter((c: any) => c.id === 16929)[0].items
    ),
    speakers: new Speakers(speakers),
    sessions: createSessions(lang, sessions),
    rooms: createRooms(lang, rooms),
  };
  /* eslint-enable */
};
